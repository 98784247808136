import {IMAGES_HOME} from '../../constants/info-homepage';

const UserInfo = ({userInfo, handleDetailUserInfo}) => {
  return (
    <div className="px-4 py-2 shadow-small rounded-2xl">
      <div>
        <span>Chào</span>
        <span className="text-primary font-semibold text-lg leading-medium ml-2 capitalize">
          {userInfo?.name || ''}
          {/* {userInfo?.firstName || ''} */}
        </span>
      </div>
      <div className="flex justify-between mt-2">
        <div className="flex items-center">
          <span>Số xu:</span>
          <span className="text-primary font-semibold text-lg leading-medium ml-2">
            {userInfo?.point || userInfo?.point === 0 ? userInfo?.point : ''}
          </span>
          <img src={IMAGES_HOME.SVGCoin} alt="coins" />
        </div>
        <div>
          <button
            onClick={handleDetailUserInfo}
            className="bg-primary text-white px-4 py-2 rounded-[29px] font-semibold leading-medium"
          >
            Xem chi tiết
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;

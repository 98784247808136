const ROUTES = {
  HOME: '/',
  PAGE_NOT_FOUND: '*',
  LOGIN: '/login',
  USER_INFO: '/user-info',
  LIST_GIFTS: '/list-gifts',
  GIFT: '/gift',
  REGISTER: '/sign-up',
  HISTORY_COIN: '/history',
  EXCHANGED_GIFT: '/exchanged-gift',
  EARNING_COIN: '/earning-coin',
  SUPPORT_EARNING_COIN: '/support-earning-coin',
};

export default ROUTES;

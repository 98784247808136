import {useEffect, useState, useCallback, useRef, useContext} from 'react';
import VitaButton from 'components/common/VitaButton';
import {BUTTON_TEXT} from 'constants/index';
import HeaderText from 'components/common/headerText';
import {InputAdornment, TextField, FormControl, Select, InputLabel, MenuItem} from '@mui/material';
import {filter} from 'lodash';
import {getProvinceApi, getDistrictWardApi} from 'apis/index';
import {LoginContext} from 'App';
import {toast} from 'react-toastify';
import {handleErrorResponse, containsSpecialChars} from 'helpers/index';
import {trim} from 'lodash';

const InputAddress = ({
  receivedAddress = {provinceId: '', districtId: '', wardId: ''},
  setReceivedAddress,
  isConfirmedAddress,
  handleClickConfirm,
  handleClickBack,
}) => {
  const {setIsLoading} = useContext(LoginContext);
  const [focusedField, setFocusedField] = useState(null);
  const wrapperRef = useRef(null);
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(true);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [fieldError, setFieldError] = useState({name: null, address: null});

  const getProvinceData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getProvinceApi();

      if (response?.response?.provinces.length > 0) {
        let provinceData = filter(response?.response?.provinces, {type: 'PROVINCE'})?.map(
          (item) => {
            return {label: item.name, value: item.id};
          }
        );

        setProvinceList(provinceData);
      } else {
        setProvinceList([]);

        if (response?.meta?.error) {
          toast.error(response?.meta?.msg || 'Lấy thông tin tỉnh/thành phố thất bại', {
            autoClose: 2000,
            position: 'top-center',
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setProvinceList([]);
      if (error?.response) {
        handleErrorResponse(error.response);
      } else {
        console.error('error from getProvinceData', error);
        toast.error('Lấy thông tin tỉnh/thành phố thất bại', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
    }
  }, []);

  const getDistrictData = useCallback(async (id) => {
    if (id) {
      setIsLoading(true);
      try {
        const response = await getDistrictWardApi(id);

        if (response?.response?.provinces.length > 0) {
          let districtData = filter(response?.response?.provinces, {type: 'DISTRICT'})?.map(
            (item) => {
              return {label: item.name, value: item.id};
            }
          );

          setDistrictList(districtData);
        } else {
          setDistrictList([]);

          if (response?.meta?.error) {
            toast.error(response?.meta?.msg || 'Lấy thông tin quận/huyện thất bại', {
              autoClose: 2000,
              position: 'top-center',
            });
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setDistrictList([]);
        if (error?.response) {
          handleErrorResponse(error.response);
        } else {
          console.error('error from getDistrictData', error);
          toast.error('Lấy thông tin quận/huyện thất bại', {
            autoClose: 2000,
            position: 'top-center',
          });
        }
      }
    }
  }, []);

  const getWardData = useCallback(async (id) => {
    if (id) {
      setIsLoading(true);
      try {
        const response = await getDistrictWardApi(id);

        if (response?.response?.provinces.length > 0) {
          let wardData = filter(response?.response?.provinces, {type: 'WARD'})?.map((item) => {
            return {label: item.name, value: item.id};
          });

          setWardList(wardData);
        } else {
          setWardList([]);

          if (response?.meta?.error) {
            toast.error(response?.meta?.msg || 'Lấy thông tin phường/xã thất bại', {
              autoClose: 2000,
              position: 'top-center',
            });
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setWardList([]);
        if (error?.response) {
          handleErrorResponse(error.response);
        } else {
          console.error('error from getWardData', error);
          toast.error('Lấy thông tin phường/xã thất bại', {
            autoClose: 2000,
            position: 'top-center',
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    const getLocationData = () => {
      if (provinceList.length === 0) getProvinceData();

      if (receivedAddress?.provinceId && districtList.length === 0)
        getDistrictData(receivedAddress?.provinceId);

      if (receivedAddress?.districtId && wardList.length === 0)
        getWardData(receivedAddress?.districtId);
    };

    const checkBtnStatus = () => {
      if (
        receivedAddress?.name &&
        receivedAddress?.provinceId &&
        receivedAddress?.districtId &&
        receivedAddress?.wardId &&
        receivedAddress?.address
      ) {
        setDisableConfirmBtn(false);
      } else setDisableConfirmBtn(true);
    };

    getLocationData();
    checkBtnStatus();
  }, [receivedAddress]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setFocusedField(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const customStyle = Object.freeze({
    textfield: {
      input: {
        fontSize: 20,
        fontWeight: 500,
        fontFamily: 'Montserrat',
        lineHeight: 1.3,
        color: '#060606',
      },
    },
    inputProps: {
      fontWeight: 600,
      width: '100%',
      '& .MuiInputAdornment-root': {
        color: '#005EB8',
      },
    },
    inputLabelProps: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: '-0.16px',
      color: '#828282',
      '& .MuiInputLabel-asterisk': {color: '#F5293A'},
      '&.Mui-focused': {color: '#005EB8'},
    },
    menuItem: {
      fontSize: 20,
      color: '#060606',
      lineHeight: 1.4,
      fontFamily: 'Montserrat',
      '&.Mui-selected': {backgroundColor: '#005EB8', color: '#fff', fontWeight: 500},
    },
    helperTextProps: {
      fontSize: 16,
      fontFamily: 'Montserrat',
      '&.Mui-error': {color: '#F5293A'},
    },
  });

  const handleChange = (event, name) => {
    let cloneData = {...receivedAddress, [name]: event.target.value};
    setReceivedAddress(cloneData);
  };

  const handleChangeSelect = (child, name) => {
    let cloneData = {...receivedAddress};
    let data = child?.props;

    switch (name) {
      case 'provinceId':
        cloneData.provinceId = data?.value;
        cloneData.provinceName = data?.children;

        cloneData.districtId = '';
        cloneData.districtName = '';

        cloneData.wardId = '';
        cloneData.wardName = '';
        setDistrictList([]);
        setWardList([]);
        break;

      case 'districtId':
        cloneData.districtId = data?.value;
        cloneData.districtName = data?.children;

        cloneData.wardId = '';
        cloneData.wardName = '';
        setWardList([]);
        break;

      case 'wardId':
        cloneData.wardId = data?.value;
        cloneData.wardName = data?.children;
        break;

      default:
        break;
    }
    setReceivedAddress(cloneData);
  };

  const onFocus = useCallback((event) => {
    setFocusedField(event.target.name);
  }, []);

  const handleClearInput = useCallback(
    (name) => {
      let cloneData = {...receivedAddress, [name]: ''};
      setReceivedAddress(cloneData);
    },

    [receivedAddress]
  );

  const validateInputAddress = () => {
    let cloneFieldError = {...fieldError};
    let inputName = trim(receivedAddress?.name);
    let inputAddress = trim(receivedAddress?.address);

    let isError = false;

    if (containsSpecialChars(receivedAddress?.name)) {
      isError = true;
      cloneFieldError.name = 'Tên không chứa ký tự đặc biệt';
    } else if (!inputName) {
      isError = true;
      cloneFieldError.name = 'Tên không được bỏ trống';
    } else {
      cloneFieldError.name = null;
    }

    if (!inputAddress) {
      isError = true;
      cloneFieldError.address = 'Địa chỉ không được bỏ trống';
    } else {
      cloneFieldError.address = null;
    }

    setReceivedAddress({...receivedAddress, name: inputName, address: inputAddress});
    setFieldError(cloneFieldError);
    return isError;
  };

  const handleConfirm = () => {
    let isError = validateInputAddress();
    if (!isError) {
      handleClickConfirm();
    } else {
      console.log('fieldError', fieldError);
      console.log('error receivedAddress', receivedAddress);
    }
  };

  return (
    <>
      <div className="bg-background">
        <HeaderText>
          <div>{isConfirmedAddress === false ? 'Đổi địa chỉ nhận quà' : 'Địa chỉ nhận quà'}</div>
        </HeaderText>

        <div className="px-4 mb-40">
          <div className="mt-6 mb-8" ref={wrapperRef}>
            <div className="mb-6">
              <TextField
                required
                label="Tên người nhận"
                name="name"
                value={receivedAddress?.name}
                variant="standard"
                onChange={(e) => handleChange(e, 'name')}
                fullWidth
                className="TextField-InputAddress customNameField"
                onFocus={onFocus}
                focused={focusedField === 'name'}
                sx={{...customStyle.textfield}}
                InputProps={{
                  endAdornment: (
                    <>
                      {receivedAddress?.name?.length > 0 && focusedField === 'name' && (
                        <InputAdornment position="end">
                          <span
                            onClick={(e) => {
                              handleClearInput('name');
                            }}
                          >
                            Xóa
                          </span>
                        </InputAdornment>
                      )}
                    </>
                  ),
                  sx: {...customStyle.inputProps},
                }}
                InputLabelProps={{
                  sx: {...customStyle.inputLabelProps},
                }}
                FormHelperTextProps={{sx: {...customStyle.helperTextProps}}}
                error={fieldError?.name !== null}
                helperText={fieldError?.name}
              />
            </div>

            <div className="mb-6">
              <FormControl variant="standard" fullWidth required>
                <InputLabel sx={{...customStyle.inputLabelProps}}>Tỉnh/Thành phố</InputLabel>
                <Select
                  label="Tỉnh/Thành phố"
                  value={provinceList.length > 0 ? receivedAddress?.provinceId : ''}
                  onChange={(e, child) => {
                    // handleChange(e, 'provinceId');
                    handleChangeSelect(child, 'provinceId');
                  }}
                  sx={{...customStyle.textfield.input}}
                >
                  {provinceList?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        name={item.label}
                        value={item.value}
                        sx={{...customStyle.menuItem}}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="grid grid-cols-2 gap-6 mb-6">
              <div>
                <FormControl variant="standard" fullWidth required>
                  <InputLabel sx={{...customStyle.inputLabelProps}}>Quận/Huyện</InputLabel>
                  <Select
                    label="Quận/Huyện"
                    value={districtList.length > 0 ? receivedAddress?.districtId : ''}
                    onChange={(e, child) => {
                      // handleChange(e, 'districtId');
                      handleChangeSelect(child, 'districtId');
                    }}
                    sx={{...customStyle.textfield.input}}
                  >
                    {districtList?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          name={item.label}
                          value={item.value}
                          sx={{...customStyle.menuItem}}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl variant="standard" fullWidth required>
                  <InputLabel sx={{...customStyle.inputLabelProps}}>Phường/Xã</InputLabel>
                  <Select
                    label="Phường/Xã"
                    value={wardList.length > 0 ? receivedAddress?.wardId : ''}
                    onChange={(e, child) => {
                      // handleChange(e, 'wardId');
                      handleChangeSelect(child, 'wardId');
                    }}
                    sx={{...customStyle.textfield.input}}
                  >
                    {wardList?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value} sx={{...customStyle.menuItem}}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="mb-6">
              <TextField
                required
                label="Địa chỉ cụ thể"
                name="address"
                value={receivedAddress?.address}
                variant="standard"
                onChange={(e) => handleChange(e, 'address')}
                fullWidth
                className="TextField-InputAddress"
                onFocus={onFocus}
                focused={focusedField === 'address'}
                sx={{...customStyle.textfield}}
                InputProps={{
                  endAdornment: (
                    <>
                      {receivedAddress?.address?.length > 0 && focusedField === 'address' && (
                        <InputAdornment position="end">
                          <span
                            onClick={(e) => {
                              handleClearInput('address');
                            }}
                          >
                            Xóa
                          </span>
                        </InputAdornment>
                      )}
                    </>
                  ),
                  sx: {...customStyle.inputProps},
                }}
                InputLabelProps={{
                  sx: {...customStyle.inputLabelProps},
                }}
                FormHelperTextProps={{sx: {...customStyle.helperTextProps}}}
                error={fieldError?.address !== null}
                helperText={fieldError?.address}
              />
            </div>
          </div>
        </div>
        <div className="fixed bottom-4 px-6 w-full">
          <div className="mb-6">
            <VitaButton disabled={disableConfirmBtn} onClick={handleConfirm}>
              {BUTTON_TEXT.CONFIRM}
            </VitaButton>
          </div>

          <div>
            <VitaButton isOutlined onClick={handleClickBack}>
              {BUTTON_TEXT.BACK}
            </VitaButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputAddress;

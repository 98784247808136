import './custom.scss';
import React from 'react';
import {CONTACT_INFO, ErrorCode, LINK_TERMS, BUTTON_TEXT} from 'constants/index';
import Button from '@mui/material/Button';

const ErrorCodeComponent = (props) => {
  const {errorCode, setErrorCode} = props;

  const renderMessageError = () => {
    switch (errorCode) {
      case ErrorCode.BlockedEarningCoin:
        return (
          <>
            <div className="custom-error-home-page-content">
              TÀI KHOẢN CỦA BẠN ĐÃ BỊ <span className="font-extrabold">KHÓA TÍNH NĂNG TÍCH XU</span>
            </div>
          </>
        );
      // case ErrorCode.BlockedSupportEarningCoin:
      //   return (
      //     <>
      //       <div className="custom-error-home-page-content">
      //         TÀI KHOẢN CỦA BẠN ĐÃ BỊ{' '}
      //         <span className="font-extrabold">KHÓA TÍNH NĂNG NHỜ TÍCH XU</span>
      //       </div>
      //     </>
      //   );
      default:
        break;
    }
  };

  return (
    <>
      <div className="custom-error-div-home-page">
        <div>{renderMessageError()}</div>
        <svg style={{width: '100%', height: 100, paddingTop: 60}}>
          <g fill="none" stroke="#939393" strokeWidth="1">
            <path strokeDasharray="10,10" d="M5 0 l1000 0" />
          </g>
        </svg>
        <div>
          <p className="custom-error-support-home-page">Cần được hỗ trợ liên hệ</p>
        </div>
        <div style={{paddingBottom: 40}} className="custom-error-support-home-page-phone">
          <a href={`tel:${CONTACT_INFO.PHONE_NUMBER}`}>{CONTACT_INFO.PHONE_NUMBER}</a>
        </div>

        <div className="px-2">
          <Button
            variant="contained"
            className="custom-home-page-button-submit"
            onClick={() => {
              return setErrorCode(null);
            }}
          >
            {BUTTON_TEXT.ON_HOMEPAGE}
          </Button>
          <Button
            variant="contained"
            className="custom-home-page-button-return"
            onClick={() => {
              setErrorCode(null);
              window.open(LINK_TERMS, '_blank', 'noopener, noreferrer');
            }}
          >
            {BUTTON_TEXT.TERMS}
          </Button>
        </div>
      </div>
    </>
  );
};
export default ErrorCodeComponent;

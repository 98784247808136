const DivideLine = ({strokeDasharray = '10,10', ...props}) => {
  return (
    <svg style={{width: '100%', height: '2px'}} {...props}>
      <g fill="none" stroke="#939393" strokeWidth="2">
        <path strokeDasharray={strokeDasharray} d="M5 0 l1000 0" />
      </g>
    </svg>
  );
};

export default DivideLine;

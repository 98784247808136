import React, {forwardRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import {FaChevronCircleLeft, FaChevronCircleRight} from 'react-icons/fa';

/*
function SampleNextArrow({onClick}) {
  return (
    <button
      className="absolute block mb-0 top-1/2 -translate-y-1/2 translate-x-1/2 right-0 hover:text-blue-500"
      onClick={onClick}
    >
      <FaChevronCircleRight size={20} />
    </button>
  );
}

function SamplePrevArrow({onClick}) {
  return (
    <button
      className="absolute block mb-0 -translate-x-1/2 top-1/2 -translate-y-1/2 hover:text-blue-500"
      onClick={onClick}
    >
      <FaChevronCircleLeft size={20} />
    </button>
  );
}
*/

const Carousel = forwardRef(({children, settings, className}, ref) => {
  return (
    <Slider
      ref={ref}
      // nextArrow={<SampleNextArrow />}
      // prevArrow={<SamplePrevArrow />}
      arrows={false}
      {...settings}
      className={className}
    >
      {children?.map((entry, index) => {
        return entry;
      })}
    </Slider>
  );
});

export default Carousel;

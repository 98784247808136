export const LOCATION = {
  getProvinceUrl: 'api/province/vn',
  getDistrictWardUrl: (id) => `api/province/parent/${id}`,
};

export const ORDER = {
  getListOrderByStatusTabUrl: 'api/order/get-list-order-by-status-tab',
};

export const GIFT = {
  getGiftCategoriesUrl: 'api/gift/categories',
  getGiftByIdUrl: (id) => `api/gift/${id}`,
};

export const USER = {
  postUserLoginUrl: 'api/user/login',
  postSendSmsUrl: 'api/user/send-otp',
  postVerifyOtpUrl: 'api/user/verify-otp',
  postRegisterCalosureUrl: 'api/user/register-calosure',
  postLoginCalosureUrl: 'api/user/login-calosure',
  postUserLogoutUrl: 'api/user/logout',
};

export const LOYALTY = {
  postSendSmsUrl: 'api-v3/loyalty/send-sms',
  postVerifyOtpUrl: 'api-v3/loyalty/verify-otp',
  getUserInfoUrl: 'api-v3/loyalty/v1/user',
  getCheckBlockedScanUrl: 'api-v3/loyalty/v1/user/blocked-scan',
  getCheckPhoneNumberUrl: 'api-v3/loyalty/v1/user/auth/check-phone-number',
  getListGiftByUserUrl: 'api-v3/loyalty/v1/user/gift/for-cls',
  getHistoryEarningCoinUrl: 'api-v3/loyalty/v1/user/notification',
  getListHighlightGiftUrl: 'api-v3/loyalty/v1/user/gift/highlight/cls',
  getGiftByUserWithCategoryIdUrl: (categoryId) =>
    `api-v3/loyalty/v1/user/gift/git-category/cls/${categoryId}`,
};

export const PRODUCT = {
  getCheckQRCode: (code) => `api/product/check?qrCode=${code}`,
  postSpoonCode: `api-v3/loyalty/v1/point`,
  presignedUrl: `api-v3/loyalty/v1/user/file/presigned-url`,
  userRequestHistoryPoint: `api-v3/loyalty/v1/user/user-request-history-point`,
  getCheckSKU: (code) => `api-v3/loyalty/v1/point/web-app-get-qr-information?qrCode=${code}`,
};

export const GIFT_EXCHANGE = {
  postExchangeEVoucherUrl: 'api/v2/point/gift-exchange',
  postExchangePhysical: `api/point/web-app-physical-gift-exchange`,
};

//recipient
export const RECIPIENT = {
  putRecipientUrl: 'api/recipient',
};
//delivery address
export const DELIVERY = {
  getInfoDelivery: 'api/recipient/calosure-addr',
  postDelivery: 'api/recipient/calosure-addr',
};

import {useEffect, useState, useCallback, useContext} from 'react';
import InputAddress from './address/inputAddress';
import ConfirmAddress from './address/confirmAddress';
import {GIFT_EXCHANGE_STEP, TYPE_RESPONSE} from 'constants/info-giftpage';
import {EX_DATA_API_RECIPIENT} from 'constants/data-test';
import {LoginContext} from 'App';
import {putRecipientApi, postSendSmsApi, getInfoDeliveryApi, postDeliveryApi} from 'apis/index';
import {handleErrorResponse} from 'helpers/index';
import {toast} from 'react-toastify';
import {API_SUCCESS_STATUS} from 'constants/index';

const PhysicalConfirmAddress = ({handleSendOTP, setGiftExchangeStep, setTypeResponse}) => {
  const CONFIRM_ADDRESS_STEP = Object.freeze({INPUT: 'INPUT', CONFIRM: 'CONFIRM'});
  const {userInfo, setIsLoading} = useContext(LoginContext);
  const [receivedAddress, setReceivedAddress] = useState({
    name: '',
    provinceId: '',
    districtId: '',
    wardId: '',
    address: '',
    phoneNumber: '',
    // valid: true,
  });
  const [confirmStep, setConfirmStep] = useState(null);
  const [isConfirmedAddress, setIsConfirmedAddress] = useState(null);
  // const [lastRecipientData, setLastRecipientData] = useState(null);

  const handlePostRecipient = useCallback(async (data) => {
    //  console.log('data from handlePostRecipient', data);
    if (data) {
      let params = {
        address: data?.address,
        districtId: data?.districtId,
        name: data?.name,
        phoneNumber: data?.phoneNumber,
        provinceId: data?.provinceId,
        wardId: data?.wardId,
        id: data?.id,
      };
      setIsLoading(true);
      try {
        const response = await postDeliveryApi(params);
        //  console.log('response from handlePostRecipient', response);
        if (response?.meta?.status === API_SUCCESS_STATUS) {
          // if success call api send sms
          await handleSendOTP(data);
        } else {
          setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
          setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
          toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
            autoClose: 2000,
            position: 'top-center',
          });
        }
      } catch (error) {
        setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
        setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
        toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // check physicalGiftExchanged
    //check api get address,
    const getRecipientData = async () => {
      setIsLoading(true);
      try {
        const response = await getInfoDeliveryApi();
        if (response?.response) {
          setReceivedAddress(response?.response);
          setConfirmStep(CONFIRM_ADDRESS_STEP.CONFIRM);
          setIsConfirmedAddress(false);
        } else {
          let initAdress = {
            ...receivedAddress,
            name: userInfo?.name,
            provinceId: userInfo?.provinceId,
            provinceName: userInfo?.provinceName,
            districtId: userInfo?.districtId,
            districtName: userInfo?.districtName,
            wardId: userInfo?.wardId,
            wardName: userInfo?.wardName,
            phoneNumber: userInfo?.phoneNumber,
            address: userInfo?.address,
          };
          setReceivedAddress(initAdress);
          setConfirmStep(CONFIRM_ADDRESS_STEP.INPUT);
        }
      } catch (error) {
        setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
        setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
        toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
      setIsLoading(false);
    };
    getRecipientData();
  }, [userInfo]);

  const renderComponent = () => {
    switch (confirmStep) {
      case CONFIRM_ADDRESS_STEP.INPUT:
        return (
          <InputAddress
            receivedAddress={receivedAddress}
            setReceivedAddress={setReceivedAddress}
            isConfirmedAddress={isConfirmedAddress}
            handleClickConfirm={handleClickConfirm_InputAddress}
            handleClickBack={handleClickBack_InputAddress}
          />
        );

      case CONFIRM_ADDRESS_STEP.CONFIRM:
        return (
          <ConfirmAddress
            receivedAddress={receivedAddress}
            handleClickConfirm={handleClickConfirm_ConfirmAddress}
            isConfirmedAddress={isConfirmedAddress}
            handleClickChangeAddress={handleClickChangeAddress_ConfirmAddress}
            handleClickBack={handleClickBack_ConfirmAddress}
          />
        );

      default:
        break;
    }
  };

  // control function of 2 sub components
  //InputAddress Component
  const handleClickConfirm_InputAddress = useCallback(() => {
    setConfirmStep(CONFIRM_ADDRESS_STEP.CONFIRM);
    setIsConfirmedAddress(true);
  }, []);

  const handleClickBack_InputAddress = useCallback(() => {
    if (isConfirmedAddress === null) {
      setGiftExchangeStep(GIFT_EXCHANGE_STEP.GIFT_DETAIL);
    } else if (isConfirmedAddress === false) {
      setConfirmStep(CONFIRM_ADDRESS_STEP.CONFIRM);
    }
  }, [isConfirmedAddress]);

  //ConfirmAddress Component
  const handleClickConfirm_ConfirmAddress = useCallback(async () => {
    // let callbackFunc = async () => {
    //   // setGiftExchangeStep(GIFT_EXCHANGE_STEP.PHYSICAL_GIFT_OTP_INPUT);

    // };

    await handlePostRecipient(receivedAddress);
  }, [receivedAddress]);

  const handleClickChangeAddress_ConfirmAddress = useCallback(() => {
    setConfirmStep(CONFIRM_ADDRESS_STEP.INPUT);
  }, []);

  const handleClickBack_ConfirmAddress = useCallback(() => {
    if (isConfirmedAddress) {
      setIsConfirmedAddress(false);
      setConfirmStep(CONFIRM_ADDRESS_STEP.INPUT);
    } else {
      setGiftExchangeStep(GIFT_EXCHANGE_STEP.GIFT_DETAIL);
    }
  }, [isConfirmedAddress]);

  return (
    <>
      <div>{renderComponent()}</div>
    </>
  );
};

export default PhysicalConfirmAddress;

import {useNavigate} from 'react-router-dom';
import ROUTES from 'constants/routes';
import VitaButton from 'components/common/VitaButton';
import DivideLine from 'components/common/divideLine';
import {BUTTON_TEXT} from 'constants/index';
import {IMAGES_HOME} from 'constants/info-homepage';
import HeaderText from 'components/common/headerText';
import parse from 'html-react-parser';

const DetailGift = ({data, handleClickExchangeGift}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-background">
        <HeaderText>
          <div>Chi tiết quà tặng</div>
        </HeaderText>

        {data && (
          <div>
            <div className="max-h-[200px] flex justify-center">
              <img src={data?.coverImage} alt={data?.name || ''} />
            </div>
            <div className="mt-1 mx-4 mb-40">
              <div className="px-2 rounded-lg bg-white shadow-small overflow-x-hidden">
                <div className="py-4 text-primary font-bold text-xl leading-medium uppercase text-center">
                  {data?.name || ''}
                </div>

                <DivideLine />
                <div className="py-4 grid grid-cols-3">
                  <div className="flex flex-col items-center">
                    <div className="text-lg tracking-tighter leading-medium font-normal">Số xu</div>
                    <div className="flex items-center text-xl font-semibold leading-medium">
                      <span>{data?.point || data?.point === 0 ? data?.point : ''}</span>
                      <span>
                        <img src={IMAGES_HOME?.SVGCoin} alt="coin" />
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-lg tracking-tighter leading-medium font-normal">
                      Giá trị
                    </div>
                    <div className="text-xl font-semibold leading-medium">
                      {data?.money?.toLocaleString() || ''}đ
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-lg tracking-tighter leading-medium font-normal">
                      Còn hàng
                    </div>
                    <div className="text-xl font-semibold leading-medium">
                      {data?.total || data?.total === 0 ? data?.total : ''}
                    </div>
                  </div>
                </div>
                <DivideLine />
                <div className="py-4 text-black text-lg tracking-tighter leading-medium font-normal">
                  {/* {data?.description || ''} */}
                  {data?.description ? parse(data?.description) : ''}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="fixed bottom-0 pb-4 px-6 pt-2 w-full bg-background">
          <div className="mb-6">
            <VitaButton disabled={!data} onClick={handleClickExchangeGift}>
              {BUTTON_TEXT.GIFT_EXCHANGE}
            </VitaButton>
          </div>
          <div>
            <VitaButton onClick={() => navigate(ROUTES.HOME)} isOutlined>
              {BUTTON_TEXT.ON_HOMEPAGE}
            </VitaButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailGift;

import React, {createContext, useState, useEffect} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';
import Home from './pages/Home';
import './App.scss';
import ROUTES from './constants/routes';
import GiftPage from './pages/Gift';
import {handleLazyLoadError, handleErrorResponse} from './helpers/index';
import Spinner from './components/common/spinner';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {localStorageGetItem, localStorageRemoveItem} from 'helpers/storage';
import {STORAGE_KEY, API_SUCCESS_STATUS, LoginStep, ErrorCode} from 'constants/index';
import {toast} from 'react-toastify';
import {getUserInfoApi, getCheckBlockedScanApi} from 'apis/index';
import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');

const LoginPage = React.lazy(() =>
  import('./pages/Login').catch((error) => {
    handleLazyLoadError(error);
  })
);

const UserInfoPage = React.lazy(() =>
  import('./pages/UserInfo').catch((error) => handleLazyLoadError(error))
);

const ListGiftsPage = React.lazy(() =>
  import('./pages/ListGifts').catch((error) => handleLazyLoadError(error))
);

const RegisterPage = React.lazy(() =>
  import('./pages/Register').catch((error) => handleLazyLoadError(error))
);

const HistoryCoinPage = React.lazy(() =>
  import('./pages/HistoryCoin').catch((error) => handleLazyLoadError(error))
);

const ExchangedGiftPage = React.lazy(() =>
  import('./pages/ExchangedGift').catch((error) => handleLazyLoadError(error))
);

const EarningCoinPage = React.lazy(() =>
  import('./pages/EarningCoin').catch((error) => handleLazyLoadError(error))
);

const SupportEarningCoinPage = React.lazy(() =>
  import('./pages/SupportEarningCoin').catch((error) => handleLazyLoadError(error))
);

const Loading = React.lazy(() =>
  import('./components/common/loading').catch((error) => handleLazyLoadError(error))
);

export const LoginContext = createContext();

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkBlockedScan, setCheckBlockedScan] = useState(null);
  const navigate = useNavigate();

  const getUserInfo = async () => {
    setIsLoading(true);
    try {
      const response = await getUserInfoApi();

      if (response?.meta?.status === API_SUCCESS_STATUS && response?.response?.user) {
        setUserInfo(response?.response?.user);
        setIsLogin(true);
        setIsLoading(false);

        if (response?.response?.user?.blocked) {
          localStorageRemoveItem(STORAGE_KEY.ACCESS_TOKEN);
          navigate(ROUTES.LOGIN, {
            state: {
              loginStep: LoginStep.ErrorCodeResponse,
              errorCode: ErrorCode.RestrictedAccount,
            },
          });
        } else {
          await getCheckBlockedScan();
        }
      } else {
        setUserInfo(null);
        setIsLogin(false);
        setIsLoading(false);
        localStorageRemoveItem(STORAGE_KEY.ACCESS_TOKEN);

        if (response?.meta?.error && response?.meta?.error === ErrorCode.RestrictedAccount) {
          navigate(ROUTES.LOGIN, {
            state: {
              loginStep: LoginStep.ErrorCodeResponse,
              errorCode: ErrorCode.RestrictedAccount,
            },
          });
        } else {
          toast.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại.', {
            autoClose: 1500,
            position: 'top-center',
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      setUserInfo(null);
      setIsLogin(false);
      if (error?.response) {
        handleErrorResponse(error.response);
      } else {
        console.error('error from getUserData', error);
        toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
    }
  };

  const getCheckBlockedScan = async () => {
    setIsLoading(true);
    try {
      const response = await getCheckBlockedScanApi();
      //  console.log('response getCheckBlockedScan', response);

      if (response?.meta?.status === API_SUCCESS_STATUS) {
        if (response?.response) {
          setCheckBlockedScan(response?.response);
        } else setCheckBlockedScan(null);
      } else {
        setCheckBlockedScan(null);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setCheckBlockedScan(null);
      if (error?.response) {
        handleErrorResponse(error.response);
      } else {
        console.error('error from getCheckBlockedScanApi', error);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      let token = localStorageGetItem(STORAGE_KEY.ACCESS_TOKEN);

      if (token && !isLogin && !userInfo) {
        await getUserInfo();
      } else {
        if (isLogin) setIsLogin(false);
        if (userInfo) setUserInfo(null);
      }
    };

    getData();
  }, []);
  const lockScreenOrientation = () => {
    if (window.screen && window.screen.orientation) {
      window.screen.orientation
        .lock('portrait')
        .then(() => {
          console.log('Màn hình đã được khóa ở chế độ portrait.');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    //lockScreenOrientation();
  }, []);
  return (
    <div className="App">
      <LoginContext.Provider
        value={{
          isLogin: isLogin,
          setIsLogin: setIsLogin,
          userInfo: userInfo,
          setUserInfo: setUserInfo,
          isLoading: isLoading,
          setIsLoading: setIsLoading,
          checkBlockedScan: checkBlockedScan,
          setCheckBlockedScan: setCheckBlockedScan,
        }}
      >
        <Loading isLoading={isLoading}>
          <React.Suspense fallback={<Spinner />}>
            <Routes>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
              <Route path={ROUTES.LOGIN} element={<LoginPage />} />
              <Route path={ROUTES.USER_INFO} element={<UserInfoPage />} />
              <Route path={ROUTES.LIST_GIFTS} element={<ListGiftsPage />} />
              <Route path={ROUTES.GIFT} element={<GiftPage />} />
              <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
              <Route path={ROUTES.HISTORY_COIN} element={<HistoryCoinPage />} />
              <Route path={ROUTES.EXCHANGED_GIFT} element={<ExchangedGiftPage />} />
              <Route path={ROUTES.EARNING_COIN} element={<EarningCoinPage />} />
              <Route path={ROUTES.SUPPORT_EARNING_COIN} element={<SupportEarningCoinPage />} />
            </Routes>
          </React.Suspense>
          <ToastContainer />
        </Loading>
      </LoginContext.Provider>
    </div>
  );
}

export default App;

import {useState, useEffect, useCallback, useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {GIFT_EXCHANGE_STEP, TYPE_RESPONSE} from 'constants/info-giftpage';
import {TYPE_OF_GIFT} from 'constants/info-homepage';
import ROUTES from 'constants/routes';
import {LoginContext} from 'App';
import DetailGift from './subPage/GiftDetail';
import ConfirmExchange from './subPage/ConfirmExchange';
import Response from './subPage/Response';
import PhysicalOTPInput from './subPage/PhysicalOTPInput';
import PhysicalConfirmAddress from './subPage/PhysicalConfirmAddress';
import {
  getGiftByIdApi,
  postExchangeEVoucherApi,
  postSendSmsApi,
  postVerifyOtpApi,
  postExchangePhysicalApi,
} from 'apis/index';
import {handleErrorResponse} from 'helpers/index';
import {toast} from 'react-toastify';
import {API_SUCCESS_STATUS, SEND_OTP_SOURCE, ErrorCode} from 'constants/index';
import checkLoginStatus from 'helpers/checkLoginStatus';

const GiftPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location;
  const {isLogin, userInfo, setIsLoading} = useContext(LoginContext);

  const [giftExchangeStep, setGiftExchangeStep] = useState(GIFT_EXCHANGE_STEP.GIFT_DETAIL);
  const [giftData, setGiftData] = useState(null);
  const [isExchangeLoading, setIsExchangeLoading] = useState(false);
  const [typeResponse, setTypeResponse] = useState(null);
  const [otpInput, setOtpInput] = useState('');
  const [isOtpError, setIsOtpError] = useState(null);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [deliveryData, setDeliveryData] = useState({});
  const [sendOtpCount, setSendOtpCount] = useState(0);

  const getGiftData = async (giftId) => {
    setIsLoading(true);
    try {
      const response = await getGiftByIdApi(giftId);
      // console.log('response from getGiftData', response);
      if (response?.meta?.status === API_SUCCESS_STATUS && response?.response) {
        setGiftData(response?.response);
      } else {
        setGiftData(null);
        if (response?.meta?.error) {
          toast.error(response?.meta?.msg || 'Lấy thông tin quà tặng thất bại', {
            autoClose: 2000,
            position: 'top-center',
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setGiftData(null);
      console.log('error?.response', error?.response);
      if (error?.response) {
        handleErrorResponse(error.response);
      } else {
        console.error('error from getGiftData', error);
        toast.error('Lấy thông tin quà tặng thất bại', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
    }
  };

  const postExchangeEVoucher = async (id) => {
    let params = {
      giftId: id,
      quantity: 1,
    };

    setIsLoading(true);
    setIsExchangeLoading(true);
    try {
      const response = await postExchangeEVoucherApi(params);
      // console.log('response from postExchangeEVoucher', response);
      if (
        response?.meta?.status === API_SUCCESS_STATUS &&
        response?.notification?.code?.[0] === TYPE_RESPONSE.E_VOUCHER_SUCCESS
      ) {
        setTypeResponse(TYPE_RESPONSE.E_VOUCHER_SUCCESS);
      } else {
        if (response?.notification?.code?.length > 0) {
          setTypeResponse(response?.notification?.code?.[0]);
        } else if (response?.meta?.error) {
          setTypeResponse(response?.meta?.error);
        } else {
          setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
        }
      }
    } catch (error) {
      setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
      console.error('error from postExchangeEVoucherApi', error);
    }

    setIsLoading(false);
    setIsExchangeLoading(false);
    setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
  };

  const handleSendOTP = useCallback(
    async (data) => {
      if (data?.phoneNumber?.length === 10) {
        if (sendOtpCount < 3) {
          let params = {
            phone: data?.phoneNumber,
            source: SEND_OTP_SOURCE.EXCHANGE_GIFT,
          };

          setSendOtpCount(sendOtpCount + 1);
          setDeliveryData(data);
          setIsLoading(true);
          try {
            const response = await postSendSmsApi(params);
            // console.log('response from postSendSmsApi', response);
            if (response?.meta?.status === API_SUCCESS_STATUS) {
              if (giftExchangeStep !== GIFT_EXCHANGE_STEP.PHYSICAL_GIFT_OTP_INPUT) {
                setGiftExchangeStep(GIFT_EXCHANGE_STEP.PHYSICAL_GIFT_OTP_INPUT);
              }
            } else {
              if (response?.meta?.error) {
                toast.error(response?.meta?.msg || 'Gửi OTP thất bại', {
                  autoClose: 2000,
                  position: 'top-center',
                });
              } else
                toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
                  autoClose: 2000,
                  position: 'top-center',
                });
            }
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
            if (error?.response) {
              handleErrorResponse(error.response);
            } else {
              console.error('error from postSendSmsApi', error);
              toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
                autoClose: 2000,
                position: 'top-center',
              });
            }
          }
        } else {
          setTypeResponse(TYPE_RESPONSE.LIMITED_OTP);
          setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
        }
      }
    },
    [sendOtpCount, giftExchangeStep]
  );

  const handleVerifyOtp = async (phoneNumber, otpInput) => {
    if (phoneNumber && otpInput) {
      let params = {
        phone: phoneNumber,
        otp: otpInput,
      };

      setIsLoading(true);
      setIsOtpLoading(true);
      try {
        const response = await postVerifyOtpApi(params);
        //console.log('response from postVerifyOtpApi', response);

        if (response?.meta?.status === API_SUCCESS_STATUS) {
          setIsOtpError(false);
          setSendOtpCount(0);

          await handleExchangePhysical(giftData?.id, deliveryData);
        } else {
          setIsOtpError(true);

          if (
            response?.meta?.error !== ErrorCode.OtpIncorrect &&
            response?.meta?.error !== ErrorCode.OtpNotExist &&
            response?.meta?.error !== ErrorCode.OtpWrong
          ) {
            setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
            setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
            toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
              autoClose: 2000,
              position: 'top-center',
            });
          }
        }
      } catch (error) {
        setIsOtpError(true);
        console.error('error from postVerifyOtpApi', error);
        setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
        setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
        toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }

      setOtpInput('');
      setIsLoading(false);
      setIsOtpLoading(false);
    }
  };

  const handleExchangePhysical = async (giftId, data) => {
    setIsLoading(true);
    let params = {
      giftId: giftId,
      quantity: 1,
      recipientName: data?.name,
      recipientPhone: data?.phoneNumber,
      provinceCode: data?.provinceId,
      districtCode: data?.districtId,
      wardCode: data?.wardId,
      streetName: data?.address,
    };
    try {
      const response = await postExchangePhysicalApi(params);
      if (
        response?.meta?.status === API_SUCCESS_STATUS &&
        response?.notification?.code?.[0] === TYPE_RESPONSE.PHYSICAL_GIFT_SUCCESS
      ) {
        setTypeResponse(TYPE_RESPONSE.PHYSICAL_GIFT_SUCCESS);
      } else {
        if (response?.notification?.code?.length > 0) {
          setTypeResponse(response?.notification?.code?.[0]);
        } else if (response?.meta?.error) {
          setTypeResponse(response?.meta?.error);
        } else {
          setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
        }
      }
    } catch (error) {
      setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
      console.error('error from postExchangePhysicalApi', error);
    }
    setIsLoading(false);
    setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
  };

  useEffect(() => {
    if (state?.giftId) {
      getGiftData(state?.giftId);
    } else {
      if (state?.giftPageState?.giftId && state?.giftPageState?.giftExchangeStep) {
        getGiftData(state?.giftPageState?.giftId);
        setGiftExchangeStep(state?.giftPageState?.giftExchangeStep);

        // GIFT_EXCHANGE_STEP.CONFIRM_EXCHANGE
      } else navigate(ROUTES.HOME);
    }
  }, []);

  useEffect(() => {
    if (
      state?.giftPageState?.giftId &&
      state?.giftPageState?.giftExchangeStep &&
      state?.giftPageState?.clickExchangeGift &&
      userInfo &&
      giftData
    ) {
      handleEnoughPoint(userInfo, giftData, () => {
        setGiftExchangeStep(GIFT_EXCHANGE_STEP.CONFIRM_EXCHANGE);
      });
    }
  }, [userInfo, giftData, state]);

  //control OTP
  useEffect(() => {
    if (otpInput?.length === 4 && !isOtpLoading) {
      handleVerifyOtp(userInfo?.phoneNumber, otpInput);
    }
  }, [otpInput]);

  const handleEnoughPoint = (userInfo, giftData, callback = null) => {
    if (userInfo?.point >= giftData?.point) {
      if (giftData?.total > 0) {
        if (callback) callback();
      } else {
        setTypeResponse(TYPE_RESPONSE.ERROR_OUT_OF_GIFTS);
        setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
      }
    } else {
      setTypeResponse(TYPE_RESPONSE.ERROR_NOT_ENOUGH_COINS);
      setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
    }
  };

  // control click to exchange gift at Gift Detail Component
  const handleClickExchangeGift = useCallback(
    (event) => {
      event.preventDefault();
      if (isLogin) {
        handleEnoughPoint(userInfo, giftData, () => {
          setGiftExchangeStep(GIFT_EXCHANGE_STEP.CONFIRM_EXCHANGE);
        });
      } else {
        checkLoginStatus(
          () => {
            handleEnoughPoint(userInfo, giftData, () => {
              setGiftExchangeStep(GIFT_EXCHANGE_STEP.CONFIRM_EXCHANGE);
            });
          },
          () => {
            navigate(ROUTES.LOGIN, {
              state: {
                routeInProgress: ROUTES.GIFT,
                giftPageState: {
                  giftId: giftData?.id,
                  giftExchangeStep: GIFT_EXCHANGE_STEP.GIFT_DETAIL,
                  clickExchangeGift: true,
                },
              },
            });
          }
        );
      }
    },
    [isLogin, giftData, userInfo]
  );

  // control click to confirm exchange gift at Confirm Exchange Component
  const handleConfirmExchange = useCallback(
    async (event) => {
      event.preventDefault();
      if (isLogin) {
        // console.log('CONFIRM EXCHANGE', giftData);

        handleEnoughPoint(userInfo, giftData, async () => {
          switch (giftData.type) {
            case TYPE_OF_GIFT.E_VOUCHER:
              if (giftData?.id) {
                await postExchangeEVoucher(giftData?.id);
              }
              break;

            case TYPE_OF_GIFT.PHYSICAL:
              setGiftExchangeStep(GIFT_EXCHANGE_STEP.PHYSICAL_GIFT_CONFIRM_ADDRESS);
              break;

            default:
              break;
          }
        });
      }
    },
    [isLogin, giftData, userInfo]
  );

  const renderSubPage = () => {
    switch (giftExchangeStep) {
      case GIFT_EXCHANGE_STEP.GIFT_DETAIL:
        return <DetailGift data={giftData} handleClickExchangeGift={handleClickExchangeGift} />;

      case GIFT_EXCHANGE_STEP.CONFIRM_EXCHANGE:
        return (
          <ConfirmExchange
            data={giftData}
            handleConfirmExchange={handleConfirmExchange}
            setGiftExchangeStep={setGiftExchangeStep}
            isExchangeLoading={isExchangeLoading}
          />
        );

      case GIFT_EXCHANGE_STEP.PHYSICAL_GIFT_CONFIRM_ADDRESS:
        return (
          <PhysicalConfirmAddress
            giftExchangeStep={giftExchangeStep}
            setGiftExchangeStep={setGiftExchangeStep}
            handleSendOTP={handleSendOTP}
            setTypeResponse={setTypeResponse}
          />
        );

      case GIFT_EXCHANGE_STEP.PHYSICAL_GIFT_OTP_INPUT:
        return (
          <PhysicalOTPInput
            userInfo={userInfo}
            otpInput={otpInput}
            setOtpInput={setOtpInput}
            isOtpError={isOtpError}
            isOtpLoading={isOtpLoading}
            handleSendOTP={handleSendOTP}
            sendOtpCount={sendOtpCount}
          />
        );

      case GIFT_EXCHANGE_STEP.RESPONSE:
        return <Response typeResponse={typeResponse} setGiftExchangeStep={setGiftExchangeStep} />;

      default:
        break;
    }
  };

  return <>{renderSubPage()}</>;
};

export default GiftPage;

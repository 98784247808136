import {useCallback} from 'react';
import {useNavigate} from 'react-router';
import ROUTES from 'constants/routes';
import GiftBlock from 'components/common/giftBlock';
import Carousel from 'components/common/carousel';

const GiftShortList = ({data, numOfItems = 5, isSeeAllGifts = true, isMoreCoin = false}) => {
  const navigate = useNavigate();

  const handleMoreGifts = useCallback(() => {
    navigate(ROUTES.LIST_GIFTS, {state: {giftType: data?.type ? data.type : null}});
  }, []);

  return (
    <div className="pb-4">
      <div className="flex justify-between">
        <div className="font-semibold leading-medium">{data?.name || ''}</div>
        {isSeeAllGifts && (
          <button
            onClick={handleMoreGifts}
            className="text-sm font-semibold leading-medium text-blue"
          >
            Xem tất cả
          </button>
        )}
      </div>
      {data?.gifts?.length < 2 ? (
        <>
          {data?.gifts?.length === 1 && (
            <div className="grid grid-cols-2 mt-3">
              <div className="px-2">
                <GiftBlock data={data?.gifts?.[0]} isMoreCoin={isMoreCoin} />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="giftShortList-slick mt-3">
          <Carousel
            settings={{
              dots: true,
              customPaging: function (i) {
                // return <div className="w-2 h-2 rounded-lg bg-primary"></div>;
                return <div className="w-4 h-2 bg-primary"></div>;
              },
              // dotsClass: 'slick-dots',
              // infinite: true,
              infinite: false,
              slidesToShow: 2,
              slidesToScroll: 2,
              // slidesToScroll: 1,
              // autoplaySpeed: 3000,
              // pauseOnHover: true,
              autoplay: false,
              // autoplay: true,
              slide: 'ul',
            }}
          >
            {data?.gifts?.length > numOfItems
              ? data?.gifts?.slice(0, numOfItems)?.map((item, index) => {
                  return (
                    <div className="px-2 h-full" key={index}>
                      <GiftBlock data={item} isMoreCoin={isMoreCoin} />
                    </div>
                  );
                })
              : data?.gifts?.map((item, index) => {
                  return (
                    <div className="px-2 h-full" key={index}>
                      <GiftBlock data={item} isMoreCoin={isMoreCoin} />
                    </div>
                  );
                })}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default GiftShortList;

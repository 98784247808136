const VitaButton = ({
  children,
  onClick,
  href,
  fill = true,
  className = '',
  isOutlined = false,
  disabled = false,
}) => {
  return (
    <button
      className={`px-12 max-[320px]:px-4 py-3 rounded-[64px] text-center text-xl font-bold leading-snug border-[1px] border-primary ${
        fill ? 'w-full' : 'w-fit'
      } ${isOutlined ? 'bg-white text-primary' : 'bg-primary text-white'} ${
        disabled ? 'disabled:opacity-50' : ''
      } ${className}`}
      onClick={onClick}
      href={href && ''}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default VitaButton;

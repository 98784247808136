import {IMAGES_HOME_PC} from '../../constants/info-homepage';
import './custom.scss';
const HomeForPc = () => {
  return (
    <div>
      <div
        className="max-w-screen min-h-screen overflow-hidden"
        style={{backgroundImage: `url('${IMAGES_HOME_PC.BACKGROUND}')`}}
      >
        <div className="mt-10 pb-28 flex flex-col gap-10 items-center custom-home-pc-logo">
          <div className="w-[222px]">
            <img src={IMAGES_HOME_PC.LOGO} alt="logo Vita Dairy" />
          </div>
          <div className="custom-home-pc-notify mx-auto p-6 w-[800px] flex flex-col items-center gap-4 text-primary text-center leading-medium bg-white rounded-3xl shadow-normal20">
            <div className="text-[40px] font-bold">THÔNG BÁO</div>
            <div className="text-[24px] font-medium custom-home-pc-content-font">
              <div>
                Để tham gia chương trình <span className="font-bold">TÍCH XU - ĐỔI QUÀ</span>
              </div>
              <div>
                Quý khách vui lòng truy cập:
                <br />
                <span className="font-bold">https://doimuongnhanqua.vn</span> trên điện thoại <br />
                hoặc quét mã QR dưới đây để tham gia.
              </div>
            </div>
            <div>
              <div className="max-w-[250px] custom-home-pc-pr">
                <img src={IMAGES_HOME_PC.QR_CODE} alt="QR CODE" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 max-w-screen overflow-hidden">
        <div className="absolute right-4 bottom-4 z-10 text-white text-xs">v.0.10</div>
        <img className="" src={IMAGES_HOME_PC.FOOTER} alt="footer" />
      </div>
    </div>
  );
};

export default HomeForPc;

const HeaderText = ({children, className = '', subClassName = '', ...props}) => {
  return (
    <div className={`sticky top-0 bg-white w-full ${className}`} {...props}>
      <div
        className={`py-[13px] flex justify-center font-semibold leading-medium border-b-[1px] border-b-[#ECECEC] text-black ${subClassName}`}
      >
        {children}
      </div>
    </div>
  );
};

export default HeaderText;

export const GIFT_EXCHANGE_STEP = {
  GIFT_DETAIL: 'GIFT_DETAIL',
  CONFIRM_EXCHANGE: 'CONFIRM_EXCHANGE',
  RESPONSE: 'RESPONSE',
  PHYSICAL_GIFT_OTP_INPUT: 'PHYSICAL_GIFT_OTP_INPUT',
  PHYSICAL_GIFT_CONFIRM_ADDRESS: 'PHYSICAL_GIFT_CONFIRM_ADDRESS',
};

export const TYPE_RESPONSE = {
  E_VOUCHER_SUCCESS: 'GF_SUCCESS',
  PHYSICAL_GIFT_SUCCESS: 'GF_PHY_SUCCESS',
  PENDING: 'GF_Inprocess',
  ERROR_FAILED_EXCHANGED: 'GF_Refund_point',
  ERROR_NOT_ENOUGH_COINS: 'GF_NO_POINT',
  ERROR_OUT_OF_GIFTS: 'GF_QUANTITI_ERR',
  ERROR_GIFT_NOT_UPDATED: 'GF_STOCK_ERR',
  // ERROR_SYSTEM_OVERLOAD: 'GF_Unknown',
  // ERROR_COIN_NOT_UPDATED: 'ERROR_COIN_NOT_UPDATED',
  ERROR_GENERAL: 'GF_Unknown',
  // ERROR_SYSTEM_MAINTAINING: 'ERROR_SYSTEM_MAINTAINING',
  LIMITED_OTP: 'LIMITED_OTP',
  ERROR_GIFT_OUTDATE: 'GF_OUTDATE',
};

export const DESCRIPTION_RESPONSE_TEXT = {
  E_VOUCHER_SUCCESS: (
    <>
      Bạn sẽ được nạp tiền điện thoại trong 24h tiếp theo. Nếu nạp tiền thất bại, bạn sẽ nhận thẻ
      cào qua tin nhắn SMS
    </>
  ),
  PHYSICAL_GIFT_SUCCESS: (
    <>
      Quà tặng sẽ <br />
      được giao trong <br />
      <span className="font-extrabold">7 ngày làm việc</span>
    </>
  ),
  PENDING: (
    <>
      Trong 24h tiếp theo nếu chưa được nạp tiền hệ thống sẽ tự động trả lại xu để bạn đổi món quà
      khác
    </>
  ),
  ERROR_FAILED_EXCHANGED: (
    <>
      Bạn đã được <span className="font-extrabold">hoàn lại xu để đổi món quà khác</span>
    </>
  ),
  ERROR_NOT_ENOUGH_COINS: (
    <>
      Bạn <span className="font-extrabold">không đủ số xu</span> để đổi quà
    </>
  ),
  ERROR_OUT_OF_GIFTS: (
    <>
      Số lượng quà <span className="font-extrabold">đã hết</span>
    </>
  ),
  ERROR_GIFT_NOT_UPDATED: (
    <>
      Số lượng <span className="font-extrabold">quà tồn kho chưa cập nhật.</span> Quay lại vào ngày
      mai để tiếp tục đổi quà.
    </>
  ),
  // ERROR_SYSTEM_OVERLOAD: (
  //   <>
  //     Hệ thống dữ liệu <span className="font-extrabold">đang quá tải.</span> Quay lại vào ngày mai
  //     để tiếp tục đổi quà.
  //   </>
  // ),
  // ERROR_COIN_NOT_UPDATED: (
  //   <>
  //     Thông tin số <span className="font-extrabold">xu đổi quà của bạn chưa được cập nhật.</span>
  //   </>
  // ),
  ERROR_GENERAL: (
    <>
      Đã có lỗi. <br />
      Quay lại vào ngày mai để tiếp tục đổi quà
    </>
  ),
  // ERROR_SYSTEM_MAINTAINING: '',
  LIMITED_OTP: (
    <>
      Vui lòng thử lại
      <br />
      vào ngày mai
    </>
  ),
  ERROR_GIFT_OUTDATE: <div className="mb-24">Quà đã hết hạn đổi</div>,
};

import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../../constants/routes';

const GiftBlock = ({data, className = '', isMoreCoin = false, ...props}) => {
  const navigate = useNavigate();

  const handleClickGift = useCallback((event) => {
    event.preventDefault();

    navigate(ROUTES.GIFT, {state: {giftId: data?.id ? data?.id : null}});
  }, []);

  return (
    <div
      onClick={handleClickGift}
      className={`max-w-[200px] w-full h-full shadow-extraSmall rounded-lg overflow-hidden ${className}`}
      {...props}
    >
      <div className="aspect-square relative">
        <img className="aspect-square" src={data?.image} alt={data?.name || ''} />

        {isMoreCoin && (
          <div className="absolute bottom-0 w-full px-2 py-1 text-center bg-overlay06 text-white font-semibold leading-medium backdrop-blur-small">
            Cần thêm xu
          </div>
        )}
      </div>
      <div className="px-1 py-2">
        <div className="font-normal text-base leading-medium tracking-tighter truncate">
          {data?.name || ''}
        </div>
        {data?.money !== null && (
          <div>
            <span className="text-gray font-normal text-sm leading-medium tracking-tighter">
              Giá trị:
            </span>
            <span className="text-primary-light font-semibold leading-medium ml-2">
              {data?.money?.toLocaleString() || ''}
            </span>
            <span className="text-primary-light font-semibold leading-medium underline">đ</span>
          </div>
        )}

        {data?.point !== null && (
          <div className="mt-2">
            <span className="text-gray font-normal text-sm leading-medium tracking-tighter">
              Số xu:
            </span>
            <span className="text-primary-light font-semibold leading-medium ml-2">
              {data?.point || data?.point === 0 ? data?.point : ''}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default GiftBlock;

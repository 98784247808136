import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  GIFT_EXCHANGE_STEP,
  TYPE_RESPONSE,
  DESCRIPTION_RESPONSE_TEXT,
} from 'constants/info-giftpage';
import Header from 'components/common/header';
import DivideLine from 'components/common/divideLine';
import VitaButton from 'components/common/VitaButton';
import {CONTACT_INFO, BUTTON_TEXT} from 'constants/index';
import ROUTES from 'constants/routes';

const Response = ({typeResponse, setGiftExchangeStep}) => {
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    renderContent();
  }, []);

  const renderContent = () => {
    let textTitle = '';
    let textDescription = '';
    switch (typeResponse) {
      case TYPE_RESPONSE.E_VOUCHER_SUCCESS:
        textTitle = (
          <>
            <span className="text-black">ĐỔI QUÀ THÀNH CÔNG</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.E_VOUCHER_SUCCESS;
        break;

      case TYPE_RESPONSE.PHYSICAL_GIFT_SUCCESS:
        textTitle = (
          <>
            <span className="text-black">ĐỔI QUÀ THÀNH CÔNG</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.PHYSICAL_GIFT_SUCCESS;
        break;

      case TYPE_RESPONSE.PENDING:
        textTitle = (
          <>
            <span className="text-black">GIAO DỊCH ĐANG XỬ LÝ</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.PENDING;
        break;

      case TYPE_RESPONSE.ERROR_FAILED_EXCHANGED:
        textTitle = (
          <>
            <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_FAILED_EXCHANGED;
        break;

      case TYPE_RESPONSE.ERROR_NOT_ENOUGH_COINS:
        textTitle = (
          <>
            <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_NOT_ENOUGH_COINS;
        break;

      case TYPE_RESPONSE.ERROR_OUT_OF_GIFTS:
        textTitle = (
          <>
            <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_OUT_OF_GIFTS;
        break;

      case TYPE_RESPONSE.ERROR_GIFT_NOT_UPDATED:
        textTitle = (
          <>
            <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_GIFT_NOT_UPDATED;
        break;

      // case TYPE_RESPONSE.ERROR_SYSTEM_OVERLOAD:
      //   textTitle = (
      //     <>
      //       <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
      //     </>
      //   );
      //   textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_SYSTEM_OVERLOAD;
      //   break;

      // case TYPE_RESPONSE.ERROR_COIN_NOT_UPDATED:
      //   textTitle = (
      //     <>
      //       <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
      //     </>
      //   );
      //   textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_COIN_NOT_UPDATED;
      //   break;

      case TYPE_RESPONSE.ERROR_GENERAL:
        textTitle = (
          <>
            <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_GENERAL;
        break;

      // case TYPE_RESPONSE.ERROR_SYSTEM_MAINTAINING:
      //   textTitle = (
      //     <>
      //       <span className="text-danger">
      //         HỆ THỐNG <br /> ĐANG BẢO TRÌ
      //       </span>
      //     </>
      //   );
      //   textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_SYSTEM_MAINTAINING;
      //   break;

      case TYPE_RESPONSE.LIMITED_OTP:
        textTitle = (
          <>
            <span className="text-danger">
              BẠN ĐÃ YÊU CẦU GỬI QUÁ <span className="font-extrabold">3 LẦN MÃ OTP</span>
            </span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.LIMITED_OTP;
        break;

      case TYPE_RESPONSE.ERROR_GIFT_OUTDATE:
        textTitle = (
          <>
            <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_GIFT_OUTDATE;
        break;

      default:
        textTitle = (
          <>
            <span className="text-danger">ĐỔI QUÀ THẤT BẠI</span>
          </>
        );
        textDescription = DESCRIPTION_RESPONSE_TEXT.ERROR_GENERAL;
        break;
    }
    setTitle(textTitle);
    setDescription(textDescription);
  };

  return (
    <>
      <div className="bg-background">
        <Header isBackground />

        <div className="px-6 py-10">
          <div className="text-2xl leading-medium font-semibold text-center">
            <div>{title}</div>
            {description && <div className="text-primary mt-4">{description}</div>}
          </div>

          <DivideLine className="my-10" />

          <div className="mb-10 text-primary leading-medium font-semibold text-center">
            <div className="text-[18px]">Cần được hỗ trợ liên hệ</div>
            <div className="text-[32px] underline">
              <a href={`tel:${CONTACT_INFO.PHONE_NUMBER}`}>{CONTACT_INFO.PHONE_NUMBER}</a>
            </div>
          </div>

          <div>
            {typeResponse === TYPE_RESPONSE.E_VOUCHER_SUCCESS ||
            typeResponse === TYPE_RESPONSE.PHYSICAL_GIFT_SUCCESS ? (
              <div className="mb-6">
                <VitaButton
                  onClick={() => {
                    window.location.pathname = ROUTES.HOME;
                    //  navigate(ROUTES.HOME)
                  }}
                >
                  {BUTTON_TEXT.ON_HOMEPAGE}
                </VitaButton>
              </div>
            ) : (
              <div className="mb-6">
                <VitaButton onClick={() => setGiftExchangeStep(GIFT_EXCHANGE_STEP.GIFT_DETAIL)}>
                  {BUTTON_TEXT.CONFIRM}
                </VitaButton>
              </div>
            )}

            {/* {typeResponse === TYPE_RESPONSE.ERROR_SYSTEM_MAINTAINING && (
              <div>
                <a href={`tel:${CONTACT_INFO.PHONE_NUMBER}`}>
                  <VitaButton isOutlined>{BUTTON_TEXT.CONTACT_CUSTOMER_CARE}</VitaButton>
                </a>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Response;

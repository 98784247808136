import {useEffect, useState} from 'react';
import Header from 'components/common/header';
import OtpInput from 'react-otp-input';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import '../custom.scss';

const PhysicalOTPInput = ({
  userInfo,
  otpInput,
  setOtpInput,
  isOtpError,
  isOtpLoading,
  handleSendOTP,
  sendOtpCount,
}) => {
  const [countDownSecond, setCountDownSecond] = useState(120);

  useEffect(() => {
    let data = document.getElementsByClassName('PhysicalOTPInput__input');
    for (let i = 0; i < data?.length; i++) {
      if (data[i].value !== '') {
        data[i].classList.add('PhysicalOTPInput__input__value');
      } else {
        data[i].classList.remove('PhysicalOTPInput__input__value');
      }
    }

    if (otpInput.length === 0) {
      data?.[0].focus();
    }
  }, [otpInput, sendOtpCount]);

  useEffect(() => {
    const timer = setInterval(function () {
      if (countDownSecond > 0) {
        setCountDownSecond(countDownSecond - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      if (timer > 0) {
        clearInterval(timer);
      }
    };
  }, [countDownSecond]);

  const handleChange = (e) => {
    setOtpInput(e);
  };

  const handleResendOtp = () => {
    setCountDownSecond(120);
    setOtpInput('');
    handleSendOTP(userInfo);
  };

  return (
    <>
      <div className="bg-background">
        <Header isBackground />

        <div className="px-6">
          <div className="mt-6 mb-2 text-gray text-[18px] leading-medium tracking-tighter text-center">
            Nhập mã OTP được gửi tới số:
          </div>
          <div className="text-primary text-[20px] leading-medium font-semibold text-center">
            {userInfo?.phoneNumber}
          </div>

          <div className="mt-6 mb-8">
            <OtpInput
              value={otpInput}
              onChange={handleChange}
              numInputs={4}
              isInputNum={true}
              containerStyle={{
                display: 'flex',
                justifyContent: 'center',
                padding: '15px',
              }}
              inputStyle="PhysicalOTPInput__input"
              shouldAutoFocus={true}
              renderInput={(props, index) => (
                <input
                  disabled={isOtpLoading || countDownSecond === 0}
                  autoFocus={index === 0 && otpInput.length === 0}
                  {...props}
                />
              )}
              inputType="number"
            />
          </div>

          {(countDownSecond === 0 || isOtpError) && (
            <div className="mb-4 text-danger text-center text-[18px] leading-medium tracking-tighter">
              {countDownSecond === 0
                ? 'Mã OTP đã hết hạn'
                : isOtpError
                ? 'Mã OTP không chính xác'
                : ''}
            </div>
          )}

          <div className="mb-6 text-center text-[18px] leading-medium tracking-tighter font-semibold">
            {countDownSecond > 0 ? (
              <p className="text-black font-normal">
                Gửi lại mã sau: <span className="text-blue">{countDownSecond}s</span>
              </p>
            ) : (
              <p onClick={handleResendOtp} className="text-primary underline">
                Gửi lại mã
              </p>
            )}
          </div>
          <div className="text-blue flex">
            <InfoOutlinedIcon fontSize="small" className="" />{' '}
            <span className="leading-medium tracking-tighter ">
              Hướng dẫn: Bạn mở tin nhắn và nhập mã bạn nhận được qua tin nhắn
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhysicalOTPInput;
